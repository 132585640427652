.btn_unwrap {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px;
}

.Bg_Video_img23 {
    /* background-color: black; */
    background-image: url(../Img/team/properties.jpg) !important;
}

.EdituserIcon {
    position: absolute;
    top: 20px;
    left: 92%;
}


.custom-form-container {
    display: flex;
    flex-direction: column;
}

.custom-row {
    display: flex;
    margin-bottom: 10px;
    /* Adjust as needed */
}

.custom-col {
    flex: 1;
    margin-right: 10px;
    /* Adjust as needed */
}

.custom-input {
    width: 100%;
    padding: 8px;
    margin: 4px 0;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
}













.spinner_1 {
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-left-color: #333;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    to {
        transform: rotate(360deg);
    }
}


.image-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
}

#image-preview {
    padding: 30px;
    position: relative;
    flex: 0 0 calc(25% - 20px);
    /* Adjust width for four images per row */
    box-sizing: border-box;
    /* Ensure padding doesn't affect width */
}

#image-preview img {
    margin-bottom: 20px;
    /* Adjust margin between images */
    width: 100%;
    height: 150px;
}

#image-preview .rajesh {
    position: absolute;
    right: 32px;
    top: 32px;
}


.add_Image {
    left: 27%;
    position: absolute;
    font-size: 30px;
    top: 155px;
}

.NumberInput {
    width: 100%;
    padding: 20px;
    margin-bottom: 20px;
}


input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}



.modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    /* Semi-transparent background */
    z-index: 1040;
    /* Below the modal's z-index */
}

.modal.show {
    display: block;
}

.modal-content {
    z-index: 1050;
}